import { Maybe } from 'shared/types'
import { WizardMode } from '../types'

export function getVariablesToVerify(
  wizardMode: WizardMode | null,
  walletAddress: string,
  signature: string,
  username: Maybe<string>,
  referrerId?: Maybe<string>
) {
  if (wizardMode === null) return null

  const signInVariables = {
    walletAddress,
    signature
  }

  const signUpVariables = {
    ...signInVariables,
    username,
    referrerId
  }

  return wizardMode === WizardMode.signIn
    ? signInVariables
    : wizardMode === WizardMode.signUp
    ? signUpVariables
    : null
}
