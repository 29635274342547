import { useCallback } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import { VERIFY_METAMASK } from 'api/auth/verify-metamask-signature'
import { reconnectWs } from 'app/apollo-middlewares'
import { WELCOME_QUERY_NAME } from 'shared/constants'
import { isBetaWelcomeDialogOpenedVar } from 'shared/store/dialogs'
import { toQueryParams } from 'shared/utils/clean-query-params'
import {
  referrerIdVar,
  userNameVar,
  walletAddressVar,
  walletSignatureVar,
  wizardModeVar
} from '../store/wizard.store'
import { WizardMode } from '../types'
import { getVariablesToVerify } from '../utils/get-variables-to-verify'
import { notifyOnVerifyingError } from '../utils/notify'
import { resetWizardState } from '../utils/reset-wizard-state'
import { useUser } from './use-user'

export function useVerifyWallet() {
  const { refetchUser } = useUser()
  const wizardMode = useReactiveVar(wizardModeVar)
  const walletSignature = useReactiveVar(walletSignatureVar)
  const walletAddress = useReactiveVar(walletAddressVar)
  const walletUsername = useReactiveVar(userNameVar)
  const referrerId = useReactiveVar(referrerIdVar)

  const [verifyMetamaskSignature, { loading }] = useMutation(VERIFY_METAMASK)

  // eslint-disable-next-line max-statements
  const verifyWallet = useCallback(async () => {
    if (!walletAddress || !walletSignature)
      throw new Error('Wallet data is missing')
    if (wizardMode === WizardMode.signUp && !walletUsername)
      throw new Error('Username is missing')

    const variables = getVariablesToVerify(
      wizardMode,
      walletAddress,
      walletSignature,
      walletUsername,
      referrerId
    )

    if (!variables) throw new Error('No variables to verify')

    verifyMetamaskSignature({
      variables,
      onError: e => {
        notifyOnVerifyingError(e)
        resetWizardState()
      },
      onCompleted: () => {
        refetchUser()
        reconnectWs()

        if (wizardMode === WizardMode.signUp) {
          toQueryParams(WELCOME_QUERY_NAME)
          isBetaWelcomeDialogOpenedVar(true)
        }
      }
    })
  }, [
    verifyMetamaskSignature,
    refetchUser,
    referrerId,
    walletAddress,
    walletSignature,
    walletUsername,
    wizardMode
  ])

  return {
    loading,
    verifyWallet
  }
}
