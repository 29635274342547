import React from 'react'
import { Flex } from '@radix-ui/themes'
import cn from 'classnames'
import { Maybe, RadixColorType } from 'shared/types'
import { formatAmountLong } from 'shared/utils/format-price'
import { TetherToken } from '../../../tether-token'
import { XyroNumeral } from '../../../xyro-numeral'
import { XyroToken } from '../../../xyro-token'
import { MIN_PNL } from '../../constants'
import styles from '../../table.module.scss'

interface Props {
  value?: Maybe<number>
  isTextColored?: boolean
  className?: string
  variant?: 'xyro' | 'tether'
  tokenColor?: Maybe<RadixColorType>
}
export const TableItemProfit: React.FC<Props> = ({
  value,
  isTextColored = false,
  className = '',
  tokenColor = null,
  variant = 'tether'
}) => {
  const profitAndLoss = value ?? 0
  const isPositiveValue = profitAndLoss >= 0
  const colorByValue = isPositiveValue ? 'green' : 'pink'
  const textColor = isTextColored ? colorByValue : 'gray'

  const tokenClassname = tokenColor
    ? `color-${tokenColor}`
    : `color-${colorByValue}`

  const formattedPNL = getFormattedPNL(profitAndLoss)

  return (
    <Flex
      gap={'2'}
      align={'center'}
      height={'100%'}
      className={cn(styles.tableItemProfit, className)}
    >
      {variant === 'tether' ? (
        <TetherToken
          size='3rem'
          className={tokenClassname}
        />
      ) : variant === 'xyro' ? (
        <XyroToken
          size='3rem'
          className={tokenClassname}
        />
      ) : null}

      <XyroNumeral
        className={cn({ 'color-white': !isTextColored })}
        color={textColor}
        isWhite={false}
        size={'3'}
        highContrast
      >
        {formattedPNL}
      </XyroNumeral>
    </Flex>
  )
}

const getFormattedPNL = (value: number) => {
  return Math.abs(value) === 0
    ? '0'
    : Math.abs(value) < MIN_PNL
    ? '<0.01'
    : formatAmountLong(value)
}
