import React from 'react'
import { Flex, Grid, Text } from '@radix-ui/themes'
import cn from 'classnames'
import { useResponsive } from 'shared/hooks/use-responsive'
import { CARD_MODES } from '../constants'
import { ModeCard } from './mode-card'
import styles from '../home.module.scss'

export const ModeCards: React.FC = () => {
  const [isMobile] = useResponsive(['xs', 'sm'])

  return (
    <Flex
      direction={'column'}
      className={styles.cardsSectionsWrapper}
      width={'100%'}
    >
      <Flex
        direction={'column'}
        gap={'1'}
        width={'100%'}
      >
        <Text
          className={cn(styles.sectionTitle, 'color-white')}
          weight={'regular'}
        >
          Game Modes
        </Text>

        {!isMobile ?
          <Flex
            direction={'column'}
            gap={'1'}
            width={'100%'}
            className={styles.desktopModeCards}
          >
            <Grid
              align={'center'}
              columns={'2fr 1fr'}
              gap={'1'}
            >
              <ModeCard
                mode={CARD_MODES[5]}
                key={CARD_MODES[5].key}
                isWide={true}
              />

              <ModeCard
                mode={CARD_MODES[2]}
                key={CARD_MODES[2].key}
              />
            </Grid>

            <Grid
              align={'center'}
              columns={'1fr 2fr'}
              gap={'1'}
            >
              <ModeCard
                mode={CARD_MODES[4]}
                key={CARD_MODES[4].key}
              />

              <ModeCard
                mode={CARD_MODES[3]}
                key={CARD_MODES[3].key}
                isWide={true}
              />
            </Grid>
          </Flex>
        : <Flex
            direction={'column'}
            gap={'1'}
            width={'100%'}
            className={styles.mobileModeCards}
          >
            <ModeCard
              mode={CARD_MODES[5]}
              key={CARD_MODES[5].key}
              isWide={true}
            />

            <Flex
              align={'center'}
              gap={'1'}
            >
              <ModeCard
                mode={CARD_MODES[2]}
                key={CARD_MODES[2].key}
              />

              <ModeCard
                mode={CARD_MODES[4]}
                key={CARD_MODES[4].key}
              />
            </Flex>

            <ModeCard
              mode={CARD_MODES[3]}
              key={CARD_MODES[3].key}
              isWide={true}
            />
          </Flex>
        }
      </Flex>

      <Flex
        direction={'column'}
        gap={'1'}
        width={'100%'}
      >
        <Text
          className={cn(styles.sectionTitle, 'color-white')}
          weight={'regular'}
        >
          Extra
        </Text>

        <Flex
          direction={{ initial: 'column', sm: 'row' }}
          gap={{ initial: '2', sm: '1' }}
          align={'center'}
          width={'100%'}
        >
          <ModeCard
            mode={CARD_MODES[1]}
            key={CARD_MODES[1].key}
            isWide={Boolean(isMobile)}
          />

          <ModeCard
            mode={CARD_MODES[0]}
            key={CARD_MODES[0].key}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
