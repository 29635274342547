import { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Flex } from '@radix-ui/themes'
import { useLoadAllowance } from 'features/auth/hooks/use-load-allowance'
import { DataTestIDs } from 'shared/constants'
import { useWallet } from 'shared/hooks/use-wallet'
import { StrongboxIcon } from 'shared/icons'
import { globalOverlayAllowanceLoadingVar } from 'shared/store/global-overlay-state-store'
import { transactionAllowanceAmountVar } from 'shared/store/transaction-allowance-amount'
import { userVar } from 'shared/store/user'
import { isOpenAllowanceDialogVar } from '../store/dialogs'
import { ApprovedBalanceContent } from './approved-balance-content'
import styles from '../approved-balance.module.scss'

export const ApprovedBalance = () => {
  const user = useReactiveVar(userVar)
  const { isReady } = useWallet()
  const { allowance, loading: loadingAllowance } = useLoadAllowance()

  useEffect(() => {
    transactionAllowanceAmountVar(allowance)
  }, [allowance])

  useEffect(() => {
    globalOverlayAllowanceLoadingVar(loadingAllowance)
  }, [loadingAllowance])

  const onClickOpenDialog = () => {
    isOpenAllowanceDialogVar(true)
  }

  if (!user || !isReady) return null

  return (
    <>
      <Flex
        className={styles.approvePopoverTrigger}
        onClick={onClickOpenDialog}
        data-testid={DataTestIDs.buttonApprovedBalanceHeader}
      >
        <StrongboxIcon />
      </Flex>
      <ApprovedBalanceContent />
    </>
  )
}
