/* eslint-disable max-statements, max-lines */
import { useCallback, useEffect, useState } from 'react'
import {
  useMutation,
  useQuery,
  useReactiveVar,
  useSubscription
} from '@apollo/client'
import {
  GameResultNotification,
  GameType,
  Maybe,
  MeFragment,
  NotificationType,
  NotificationsSubscription,
  PredictStatus,
  User
} from '__generated__/graphql'
import { GET_NOTIFICATIONS } from 'api/general/get-notifications'
import { GET_UNREAD_NOTIFICATIONS_COUNT } from 'api/general/get-unread-notifications-count'
import { MARK_NOTIFICATIONS_READ } from 'api/general/mark-notifications-read'
import { SUBSCRIPTION_GLOBAL_NOTIFICATIONS } from 'api/general/subscription-global-notifications'
import { NotificationToastTypes } from 'features/notification/types'
import { useNavigate } from 'react-router-dom'
import { MY_BETS_MODES } from 'shared/constants'
import {
  notificationListVar,
  notificationStateVar,
  notificationsSkipAmountVar
} from 'shared/store/notification'

const DEFAULT_TAKE_NOTIFICATIONS_AMOUNT = 15
const DEFAULT_READ_NOTIFICATION_TIMEOUT = 1000

// TODO: Optimize this hook, as it may contain heavy calculations
// NOTE: Very dirty and big hook, needs proper refactoring
// eslint-disable-next-line max-statements
export const useControlNotifications = (
  user: User | null,
  isNotificationListOpen = false
) => {
  const [lastNotification, setLastNotification] =
    useState<GameResultNotification | null>(null)

  const { data } = useSubscription<NotificationsSubscription>(
    SUBSCRIPTION_GLOBAL_NOTIFICATIONS,
    { shouldResubscribe: true, variables: { userId: user?.id } }
  )

  const { data: unreadNotificationCount, refetch: refetchUnreadNotifications } =
    useQuery(GET_UNREAD_NOTIFICATIONS_COUNT)

  const [unreadNotificationsAmount, setUnreadNotificationsAmount] =
    useState<number>(0)

  const navigate = useNavigate()

  const currentNotifications = useReactiveVar(notificationListVar)

  const notificationsSkipAmount = useReactiveVar(notificationsSkipAmountVar)

  const [commitMarkNotificationsRead] = useMutation(MARK_NOTIFICATIONS_READ)

  const {
    data: notificationsQuery,
    loading,
    refetch: refetchNotificationList
  } = useQuery(GET_NOTIFICATIONS, {
    variables: {
      data: {
        take: DEFAULT_TAKE_NOTIFICATIONS_AMOUNT,
        skip: notificationsSkipAmount
      }
    },
    skip: !isNotificationListOpen
  })

  useEffect(() => {
    if (!isNotificationListOpen) return

    const ureadNotifications = currentNotifications.filter(
      el => el.isRead !== true
    )

    if (ureadNotifications.length === 0) return

    const newNotificationIDs = ureadNotifications?.map(
      (el: GameResultNotification) => {
        return el.id
      }
    )

    handleReadNotifications(newNotificationIDs, currentNotifications)
  }, [isNotificationListOpen])

  useEffect(() => {
    const unreadAmount =
      unreadNotificationCount?.getUnreadNotificationsCount || 0

    setUnreadNotificationsAmount(unreadAmount)
  }, [unreadNotificationCount])

  const handleReadNotifications = (
    notificationsIDs: string[],
    newNotifications: GameResultNotification[]
  ) => {
    if (
      !notificationsIDs ||
      !isNotificationListOpen ||
      notificationsIDs.length === 0
    )
      return

    setTimeout(async () => {
      await commitMarkNotificationsRead({
        variables: {
          data: {
            ids: notificationsIDs
          }
        }
      })

      const notificationsIDsSet = new Set(notificationsIDs)

      const notificationListWithUpdatedStatuses = newNotifications?.map(
        notification => {
          if (notificationsIDsSet.has(notification.id)) {
            return { ...notification, isRead: true }
          }
          return notification
        }
      )

      if (notificationListWithUpdatedStatuses.length === 0) return

      notificationListVar(notificationListWithUpdatedStatuses)
      refetchUnreadNotifications()
    }, DEFAULT_READ_NOTIFICATION_TIMEOUT)
  }

  useEffect(() => {
    const notificatons: GameResultNotification[] =
      notificationsQuery?.getNotifications?.notifications

    if (!notificatons) return

    const filteredNotifications = notificatons.filter(
      notification =>
        !currentNotifications.some(
          existingNotification => notification.id === existingNotification.id
        )
    )

    if (filteredNotifications.length === 0) return

    const newNotifications = [...currentNotifications, ...filteredNotifications]

    notificationListVar(newNotifications)

    const newNotificationIDs = notificatons?.map(el => {
      return el.id
    })

    handleReadNotifications(newNotificationIDs, newNotifications)
  }, [notificationsQuery])

  const handleControlGameNotifications = useCallback(
    // eslint-disable-next-line max-statements, complexity
    (notification: GameResultNotification) => {
      if (notification.type === NotificationType.Bonus) {
        notificationStateVar({
          isOpen: true,
          title: `Congratulations!`,
          type: NotificationToastTypes.BONUS,
          winAmount: '0.001',
          description:
            'You’ve completed the first challenge «The way of XYRO» and earned 0.001 test ETH. Enjoy the game!'
        })

        return
      }

      const { gameType, amount, outcome } = notification?.payload || {}

      if (!('status' in notification.payload)) return

      const status = notification.payload.status

      const gameName = MY_BETS_MODES[gameType].name
      const actionLink = MY_BETS_MODES[gameType].link

      const actionButtonText = `GO TO ${gameName.toUpperCase()}`
      const winAmount = outcome && amount ? (outcome - amount).toFixed(2) : ''

      if (status === PredictStatus.Won) {
        notificationStateVar({
          isOpen: true,
          title: `${gameName} is over. Congratulations, you won!`,
          type: NotificationToastTypes.WIN,
          winAmount:
            gameType === GameType.Bullseye ? outcome?.toFixed(2) : winAmount,
          description: 'You can start new game right now to earn even more.',
          buttonAction: () => navigate(actionLink)
        })
      } else if (status === PredictStatus.Loss) {
        notificationStateVar({
          isOpen: true,
          title: `${gameName} is over. Unfortunally, you lost!`,
          type: NotificationToastTypes.LOSS,
          winAmount: null,
          description: 'You can start new game right now.',
          buttonAction: () => navigate(actionLink),
          actionText: actionButtonText
        })
      } else if (status === PredictStatus.Reject) {
        notificationStateVar({
          isOpen: true,
          title: `${gameName} game was rejected.`,
          type: NotificationToastTypes.REJECT,
          description: 'You can start new game right now.',
          buttonAction: () => navigate(actionLink),
          actionText: actionButtonText
        })
      }
    },
    [navigate]
  )

  useEffect(() => {
    if (user) return
    refetchNotificationList()
    refetchUnreadNotifications()
  }, [user, refetchNotificationList, refetchUnreadNotifications])

  useEffect(() => {
    const notification = data?.notifications as GameResultNotification

    if (!notification) return

    const isNotificationExists = currentNotifications.find(
      el => el.id === notification?.id
    )

    if (isNotificationExists || notification.id === lastNotification?.id) return

    handleControlGameNotifications(notification)

    setLastNotification(notification)

    if (currentNotifications.length === 0) {
      refetchUnreadNotifications()
    } else {
      notificationListVar([notification, ...currentNotifications])
      setUnreadNotificationsAmount(prev => prev + 1)
    }
  }, [
    data,
    handleControlGameNotifications,
    refetchNotificationList,
    refetchUnreadNotifications
  ])

  return {
    loading,
    unreadNotificationsAmount,
    refetchNotificationList,
    refetchUnreadNotifications
  }
}
