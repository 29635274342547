import { useReactiveVar } from '@apollo/client'
import { Box, Flex, Text } from '@radix-ui/themes'
import { transactionAllowanceAmountVar } from 'shared/store/transaction-allowance-amount'
import { TetherToken } from 'shared/ui'
import styles from '../approved-balance.module.scss'

export const ApprovedBalanceAmount = () => {
  const allowance = useReactiveVar(transactionAllowanceAmountVar)

  return (
    <Box
      className={styles.approvedAmountBox}
      mt={'6'}
    >
      <Flex
        direction={'column'}
        className={styles.approvedAmountContent}
      >
        <Text
          size={'1'}
          className={styles.approvedAmountTitle}
          weight={'bold'}
        >
          Currently Approved:
        </Text>

        <Flex align={'center'}>
          <Flex
            justify={'center'}
            className={styles.approvedAmountTokenIcon}
          >
            <TetherToken size='5rem' />
          </Flex>
          <Text size={'8'}>{allowance}</Text>
        </Flex>
      </Flex>
    </Box>
  )
}
