import { useState } from 'react'
import { Maybe } from '__generated__/graphql'
import { POLLING_INTERVAL } from 'app/constants'
import { wagmiConfig } from 'app/wagmi-config'
import { addressList } from 'contracts/address'
import { Log } from 'viem'
import { useAccount, useWatchContractEvent } from 'wagmi'
import { abi } from '../abi'

export const useAlloanceEvents = () => {
  const [event, setEvent] = useState<Maybe<Log>>(null)
  const { address } = useAccount()

  useWatchContractEvent({
    abi,
    address: addressList.usdc,
    config: wagmiConfig,
    eventName: 'Approval',
    pollingInterval: POLLING_INTERVAL,
    onLogs: logs => {
      if (logs.length === 0) return
      const { owner } = logs[0].args

      if (owner === address) {
        setEvent(logs[0])
      }
    }
  })

  return { event }
}
