import { gql } from '__generated__'

export const FRAGMENT_ME = gql(`
  fragment Me on User {
    __typename
    id
    name
    bio
    avatarKeys
    discordRoles
    avatarUris
    isInfluencer
    wallet {
      address
    }
  }
`)
