import { Flex, Text } from '@radix-ui/themes'

import { ApprovedBalanceForm } from './approved-balance-form'
import { ApprovedBalanceAmount } from './approved-balance-amount'

import { XyroDialog } from 'shared/ui'
import { useReactiveVar } from '@apollo/client'
import { isOpenAllowanceDialogVar } from '../store/dialogs'

import styles from '../approved-balance.module.scss'

export const ApprovedBalanceContent = () => {
  const isOpenDialog = useReactiveVar(isOpenAllowanceDialogVar)

  const onCloseDialog = () => {
    isOpenAllowanceDialogVar(false)
  }

  return (
    <XyroDialog
      open={isOpenDialog}
      onOpenChange={onCloseDialog}
    >
      <Flex
        className={styles.approvedBalanceContent}
        direction={'column'}
        align={'center'}
      >
        <Text
          size={'7'}
          weight={'medium'}
          className='color-white'
        >
          Approved Balance
        </Text>

        <Text
          size={'1'}
          className={styles.approvedContentSubtitle}
          mt={'3'}
          weight={'light'}
        >
          You can authorize the amount to be used
        </Text>

        <ApprovedBalanceAmount />

        <ApprovedBalanceForm />
      </Flex>
    </XyroDialog>
  )
}
