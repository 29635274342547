import { FormEvent, useCallback, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import * as RadixForm from '@radix-ui/react-form'
import { Box, Button, Flex, Text } from '@radix-ui/themes'
import cn from 'classnames'
import { useUsdc } from 'contracts/usdc'
import { balanceVar } from 'shared/store/balance-store'
import { transactionAllowanceAmountVar } from 'shared/store/transaction-allowance-amount'
import { AmountField, XyroLoading } from 'shared/ui'
import { getFormValues } from 'shared/utils/get-form-values'
import { showNotificationToast } from 'shared/utils/notify'
import { ApporveBalanceFormValues } from '../types'
import styles from '../approved-balance.module.scss'

export const ApprovedBalanceForm = () => {
  const [isLoading, setLoading] = useState(false)
  const [isDisabledButton, setButtonDisabled] = useState(true)
  const balance = useReactiveVar(balanceVar)
  const usdc = useUsdc()

  const handleChangeAmountValue = useCallback(
    (value: string) => setButtonDisabled(!value),
    [setButtonDisabled]
  )

  const formattedBalance = Number(balance.toFixed(2))

  const handleSubmit = useCallback(
    // eslint-disable-next-line max-statements
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      const formValues = getFormValues<
        ApporveBalanceFormValues,
        ApporveBalanceFormValues
      >(event.target as HTMLFormElement)

      if (!usdc) return

      setLoading(true)
      showNotificationToast({
        title: 'Approve action in your wallet',
        type: 'info'
      })
      try {
        await usdc.approveAmount(Number(formValues.amount))
        showNotificationToast({
          title: `${formValues.amount}USDT approved`,
          type: 'success'
        })
      } catch (error) {
        showNotificationToast({
          title: `Something went wrong`,
          description: 'Please try again',
          type: 'success'
        })

        throw error
      } finally {
        setLoading(false)
      }
    },
    [usdc, setLoading]
  )

  const balanceValue = useMemo(() => `${balance.toFixed(2)} USDT`, [balance])

  return (
    <Box mt={'6'}>
      <RadixForm.Root onSubmit={handleSubmit}>
        <Flex
          direction={'column'}
          align={'center'}
        >
          <Flex
            className={styles.approvedFormAmountLabel}
            gap={'1'}
          >
            <Text
              size={'1'}
              weight={'bold'}
              color='gray'
            >
              WALLET BALANCE:
            </Text>

            <Text
              size={'1'}
              weight={'bold'}
              className={'color-white'}
            >
              {balanceValue}
            </Text>
          </Flex>

          <AmountField
            onInputChange={handleChangeAmountValue}
            name={'amount'}
            className={styles.approvedFormAmount}
            maxAmount={formattedBalance}
            maxButtonEnabled
          />

          <Button
            className={cn(styles.approvedFormButton, {
              [styles.approvedFormButtonDisabled]: isDisabledButton || isLoading
            })}
            disabled={isDisabledButton || isLoading}
            variant={isLoading ? 'outline' : 'classic'}
            type={'submit'}
            size={'4'}
            mt={'6'}
          >
            <XyroLoading loading={isLoading}>
              <Text
                size={'2'}
                weight={'bold'}
              >
                APPROVE
              </Text>
            </XyroLoading>
          </Button>
        </Flex>
      </RadixForm.Root>
    </Box>
  )
}
