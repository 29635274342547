import { useEffect } from 'react'
import { useLoadBalance } from 'contracts/usdc'
import { balanceLoadingVar, balanceVar } from 'shared/store/balance-store'
import { globalOverlayBalanceLoadingVar } from 'shared/store/global-overlay-state-store'

export const useBalanceLoader = () => {
  const { balance, loading, error } = useLoadBalance()

  useEffect(() => {
    if (!balance) return

    balanceVar(Number(balance))
  }, [balance])

  useEffect(() => {
    balanceLoadingVar(loading)
    globalOverlayBalanceLoadingVar(loading && !error)
  }, [loading, error])

  return {
    loading,
    error: Boolean(error)
  }
}
